import React, { useEffect } from "react";
import Layout from "../components/layout";
import { NotFoundContent } from '../components/not_found';
import { refreshSessionId } from '../utils/session_id';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "Not Found" });


const NotFoundPage = ({ data, location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
          location={ location }
          removeHorizontalPadding={ true } >
          
          <NotFoundContent />
          
        </Layout>
    );
};

export default NotFoundPage;
