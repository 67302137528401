import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { merge } from '../utils/functional';

import { colors } from './colors';
import * as cssStyles from '../pages/about.module.css';



export const notFoundStyles = {
    
    heading: {
        width: '100%',
        fontFamily: '"Overpass", sans-serif',
        fontSize: '28px',
        fontWeight: 'bold',
        lineHeight: '1.07',
        color: colors.black,
        textAlign: 'center'
    },

    body: {
        width: '100%',
        fontSize: '15px',
        lineHeight: '1.87',
        letterSpacing: '0.15px',
        color: colors.black
    }
};



export const NotFoundContent = () => {
    return (
        <div
          style={{
              width: `100%`,
              overflow: `hidden`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `center`
          }} >

          <div
            className={ cssStyles.container }
            style={{
                margin: `150px 0`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `center`
            }} >

            <div
              style={ notFoundStyles.heading } >
              Not Found
            </div>

            <div
              style={ merge(notFoundStyles.body, {
                  margin: `20px 0 0`,
                  textAlign: `center`
              })} >
              The page you're looking for does not exist.
            </div>

            <div
              style={ merge( notFoundStyles.body, {
                  margin: `20px 0 0`,
                  textAlign: `center`
              })} >
              
              <span>Try a</span>

              <Link
                to={ '/search' }
                style={{
                    margin: '0 5px',
                    color: colors.green
                }} >
                search
              </Link>

              <span>instead or contact us at</span>

              <OutboundLink
                style={{
                    margin: '0 5px',
                    color: colors.green
                }}
                href='mailto:help@6pages.com'>
                help@6pages.com
              </OutboundLink>

              <span>if you need assistance.</span>
              
            </div>
          </div>
        </div>
    );
};
